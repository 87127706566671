<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('blockedtimes')"
        @save="onSubmit"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <div class="mb-2">
            <b-form-checkbox v-model="currentItem.is_active" value="1" name="status" class="custom-control-success">
              {{ $t("Active") }}
            </b-form-checkbox>
          </div>
          <hr/>
          <b-row>
            <b-col md="6" xl="6">
              <validation-provider #default="{ errors }" :name="$t('Start date')" ref="start_date" rules="required"
                                   vid="start_date">
                <b-form-group :label="$t('Start date')">
                  <b-form-datepicker id="start_date" ref="start_date" v-model="currentItem.start_date"
                                     :placeholder="$t('Start date')"
                                     :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                     v-bind="labels[locale] || {}" :min="new Date().toISOString().substr(0, 10)"
                                     :max="(currentItem.end_date ? currentItem.end_date : null)"
                                     :state="getValidationState(errors)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('start_date')" class="text-danger">{{
                      $t(validation.start_date[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" rules="required" :name="$t('Start time')" vid="start_time">
                <b-form-group :label="$t('Start time')">
                  <b-form-input v-mask="'##:##'" placeholder="hh:mm" v-model="currentItem.start_time"
                                :state="getValidationState(errors)" class="form-control"/>

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('start_time')" class="text-danger">{{
                      $t(validation.start_time[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6">
              <validation-provider #default="{ errors }" rules="required|dateChecker:@start_date" :name="$t('End date')"
                                   vid="end_date">
                <b-form-group :label="$t('End Date')">
                  <b-form-datepicker v-model="currentItem.end_date" :placeholder="$t('End Date')"
                                     :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                     v-bind="labels[locale] || {}"
                                     :min="(currentItem.start_date ? currentItem.start_date : new Date().toISOString().substr(0, 10))"
                                     :state="getValidationState(errors)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('end_date')" class="text-danger">{{
                      $t(validation.end_date[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" :name="$t('end_time')"
                                   rules="required|timeChecker:@start_time,@start_date,@end_date" vid="end_time">
                <b-form-group :label="$t('End time')">
                  <b-form-input v-mask="'##:##'" placeholder="hh:mm" v-model="currentItem.end_time"
                                :state="getValidationState(errors)" class="form-control"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('end_time')" class="text-danger">{{
                      $t(validation.end_time[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <b-button variant="success" type="submit" @click.prevent="onSubmit">
        {{ $t("Save") }}
      </b-button>
    </b-card>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormTimepicker,
  BFormSelect,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver, localize} from "vee-validate";
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import {required, alphaNum, email} from "@validations";
import {mapActions} from "vuex";
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {extend} from 'vee-validate';
import moment from 'moment'
import {setDatePickerTranslation} from "@core/mixins/datePickerLocales";

extend('dateChecker', {
  params: ['target'],
  validate(value, {target}) {
    let start_date = target;
    let end_date = value;
    return moment(start_date).isSameOrBefore(end_date);
  },
  message: ('End date must be at least the same date as the start date')
});


extend('timeChecker', {
  params: ['start_time', 'start_date', 'end_date'],
  validate(value, {start_time, start_date, end_date}) {
    let start = start_date + ' ' + start_time;
    let end = end_date + ' ' + value;

    return moment(start).isSameOrBefore(end);
  },
  message: ('End date must be at least the same date as the start date')
});


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BFormSelect,
    Cleave,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    localize
  },
  mixins: [setDatePickerTranslation],
  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {
        is_active: false,
        start_date: null,
        start_time: "",
        end_date: null,
        end_time: "",
      },
      loaded: true,
      validation: {},
      validate: false,
    };
  },

  methods: {
    ...mapActions(['ADD_BLOCKED_TIME']),
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },

    onSubmit() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}

            if (success) {
              this.ADD_BLOCKED_TIME(this.currentItem).then(() => {
                this.$store.dispatch('GET_USER_ONBOARDING')
                this.$router.push({name: 'blockedtimes'});
              }).catch(error => {
                if (error.response.status == 422) {
                  this.validation = error.response.data.errors;
                  this.validate = true
                } else {
                  this.$bvToast.toast(this.$i18n.t(error.response.data.message), {
                    title: this.$i18n.t(error.response.data.message),
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                  });
                }

              })
            }
          })
    }
  },
};
</script>
